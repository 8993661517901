


































































import { array, function as fn, option, readonlyArray } from 'fp-ts'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import { from, of, Subject } from 'rxjs'
import { concatMap, filter, takeUntil, mapTo } from 'rxjs/operators'
import { DeepReadonly } from 'ts-essentials'
import { isActionOf } from 'typesafe-actions'
import Vue, { VueConstructor } from 'vue'
import { ServiceFeatureType } from '@/api/definitions'
import Page from '@/components/Page.vue'
import PasswordMasonry from '@/components/PasswordMasonry.vue'
import UserMenu from '@/components/toolbar-with-menu/UserMenu.vue'
import { getUidService } from '@/cryptography/uid_service'
import { Key } from '@/redux/domain'
import { backgroundAuthnError } from '@/redux/modules/authn/selectors'
import { ackFeaturePrompt } from '@/redux/modules/user/account/actions'
import { canAccessApi, featurePrompts } from '@/redux/modules/user/account/selectors'
import { userKeysUpdate } from '@/redux/modules/user/keys/actions'
import { Clique, cliques, getCliqueRepr } from '@/redux/modules/user/keys/selectors'

enum UpdateType {
  SCROLL = 'SCROLL',
  MATCH = 'MATCH'
}

interface FuseInput {
  name: string;
  repr: DeepReadonly<Key>;
}
const FUSE_OPTIONS: Fuse.IFuseOptions<FuseInput> = { keys: ['repr.tags'] }

export default (Vue as VueConstructor<Vue>).extend({
  props: ['debounceMillis'],
  components: {
    page: Page,
    passwordMasonry: PasswordMasonry,
    userMenu: UserMenu
  },
  data () {
    return {
      showMenu: false,
      query: '',
      updateQueue$: new Subject<UpdateType>(),
      newCliques: [] as string[],
      matchedCliques: [] as string[]
    }
  },
  computed: {
    backgroundAuthnError (): boolean {
      return backgroundAuthnError(this.$data.$state)
    },
    canAccessApi (): boolean {
      return canAccessApi(this.$data.$state)
    },
    cliques (): DeepReadonly<Clique[]> {
      return cliques(this.$data.$state)
    },
    matchedItems (): DeepReadonly<Clique[]> {
      return fn.pipe(
        this.matchedCliques,
        array.map((matchedClique) => readonlyArray.findFirst(
          (clique: DeepReadonly<Clique>) => clique.name === matchedClique
        )(this.cliques)),
        array.compact
      )
    },
    featurePrompts () {
      return featurePrompts(this.$data.$state)
    },
    anyFeaturePrompts (): boolean {
      return this.featurePrompts.length > 0
    },
    releasePrompt (): boolean {
      return this.featurePrompts.findIndex(
        (fp) => fp.featureType === ServiceFeatureType.RELEASE) === 0
    },
    toolbarIsExtended (): boolean {
      return this.$vuetify.breakpoint.xsOnly
    },
    toolbarSearchSlot (): string {
      return this.toolbarIsExtended ? 'toolbarExtension' : 'toolbarDefault'
    },
    fuseInputs (): FuseInput[] {
      return array.compact(
        this.cliques.map((clique: DeepReadonly<Clique>) => fn.pipe(
          getCliqueRepr(clique),
          option.map((repr) => ({ name: clique.name, repr }))
        ))
      )
    },
    fuseInstance (): Fuse<FuseInput> {
      return new Fuse(this.fuseInputs, FUSE_OPTIONS)
    }
  },
  methods: {
    menuSwitch (value: boolean) {
      this.showMenu = value
    },
    ackReleasePrompt () {
      this.dispatch(ackFeaturePrompt(ServiceFeatureType.RELEASE))
    },
    addKey () {
      this.newCliques.unshift(getUidService().v4())
      this.updateQueue$.next(UpdateType.SCROLL)
    },
    scrollAndMatch () {
      this.updateQueue$.next(UpdateType.SCROLL)
      this.updateQueue$.next(UpdateType.MATCH)
    },
    setQuery (value: string) {
      this.query = value
      this.scrollAndMatch()
    },
    evolveAddition (clique: string, attach: boolean) {
      this.newCliques = this.newCliques.filter(
        (newClique) => newClique !== clique)
      if (attach) {
        this.setQuery('')
      }
    }
  },
  created () {
    if (this.debounceMillis !== null) {
      this.scrollAndMatch = debounce(this.scrollAndMatch, this.debounceMillis)
    }
    this.updateQueue$.pipe(
      concatMap((updateType) => {
        switch (updateType) {
          case UpdateType.SCROLL:
            return from(this.$vuetify.goTo(0)).pipe(
              mapTo(updateType)
            )
          case UpdateType.MATCH: {
            const query = this.query.trim()
            if (query === '') {
              this.matchedCliques = this.cliques.map((item) => item.name)
            } else {
              this.matchedCliques = this.fuseInstance.search(query).map(
                (result) => result.item.name)
            }
            return of(updateType)
          }
        }
      }),
      takeUntil(this.$data.$destruction)
    ).subscribe()
    this.updateQueue$.next(UpdateType.MATCH)
    this.$data.$actions.pipe(
      filter(isActionOf(userKeysUpdate)),
      takeUntil(this.$data.$destruction)
    ).subscribe(() => {
      this.updateQueue$.next(UpdateType.MATCH)
    })
  },
  mounted () {
    if (this.matchedCliques.length > 0) {
      ;(this.$refs.search as HTMLInputElement).focus()
    }
  }
})
