





































import Vue from 'vue'
import { enableAnalysis, disableAnalysis } from '@/redux/modules/user/security/actions'
import { isAnalysisOn } from '@/redux/modules/user/security/selectors'
import CompromisedPasswords from './CompromisedPasswords.vue'
import DuplicatePasswords from './DuplicatePasswords.vue'
import VulnerablePasswords from './VulnerablePasswords.vue'

export default Vue.extend({
  components: {
    compromisedPasswords: CompromisedPasswords,
    duplicatePasswords: DuplicatePasswords,
    vulnerablePasswords: VulnerablePasswords
  },
  computed: {
    isAnalysisOn (): boolean {
      return isAnalysisOn(this.$data.$state)
    }
  },
  methods: {
    toggle () {
      this.dispatch(this.isAnalysisOn ? disableAnalysis() : enableAnalysis())
    }
  }
})
