


















































































import { option, function as fn } from 'fp-ts'
import { filter, takeUntil } from 'rxjs/operators'
import { DeepReadonly } from 'ts-essentials'
import Vue, { VueConstructor } from 'vue'
import {
  ServiceAcceptOtpParamsResponseError,
  ServiceResetOtpResponseError
} from '@/api/definitions'
import { remoteDataErrorIndicator } from '@/components/form_validators'
import { isActionSuccess } from '@/redux/flow_signal'
import { showToast } from '@/redux/modules/ui/toast/actions'
import {
  generateOtpParams,
  otpParamsGenerationReset,
  OtpParams,
  acceptOtpParams,
  otpParamsAcceptanceSignal,
  otpParamsAcceptanceReset,
  resetOtp,
  otpResetSignal,
  cancelOtpReset
} from '@/redux/modules/user/account/actions'
import {
  isOtpEnabled,
  canAccessApi,
  OtpParamsGeneration,
  otpParamsGeneration,
  OtpParamsAcceptance,
  otpParamsAcceptance,
  OtpReset,
  otpReset
} from '@/redux/modules/user/account/selectors'
import { hasIndicator, data } from '@/redux/remote_data'

const activationIncorrectValidator = remoteDataErrorIndicator(ServiceAcceptOtpParamsResponseError.INVALIDCODE)
const deactivationIncorrectValidator = remoteDataErrorIndicator(ServiceResetOtpResponseError.INVALIDCODE)

interface Mixins {
  activation: { untouchedSinceDispatch: boolean; otp: string };
  deactivation: { untouchedSinceDispatch: boolean; otp: string };
  otpParamsGeneration: DeepReadonly<OtpParamsGeneration>;
  maybeOtpParams: DeepReadonly<OtpParams> | null;
  otpParamsAcceptance: DeepReadonly<OtpParamsAcceptance>;
  otpParamsAcceptanceInProgress: boolean;
  otpReset: DeepReadonly<OtpReset>;
  otpResetInProgress: boolean;
}

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  data () {
    return {
      seedView: 0,
      activation: {
        otp: '',
        untouchedSinceDispatch: false
      },
      deactivation: {
        otp: '',
        untouchedSinceDispatch: false
      }
    }
  },
  validations: {
    activation: {
      correct () {
        return !activationIncorrectValidator(this.otpParamsAcceptance, this.activation.untouchedSinceDispatch)
      }
    },
    deactivation: {
      correct () {
        return !deactivationIncorrectValidator(this.otpReset, this.deactivation.untouchedSinceDispatch)
      }
    }
  },
  computed: {
    canAccessApi (): boolean {
      return canAccessApi(this.$data.$state)
    },
    isOtpEnabled (): boolean {
      return isOtpEnabled(this.$data.$state)
    },
    otpParamsGeneration (): DeepReadonly<OtpParamsGeneration> {
      return otpParamsGeneration(this.$data.$state)
    },
    otpParamsGenerationInProgress (): boolean {
      return hasIndicator(this.otpParamsGeneration)
    },
    maybeOtpParams (): DeepReadonly<OtpParams> | null {
      return fn.pipe(this.otpParamsGeneration, data, option.toNullable)
    },
    otpParamsAcceptance (): DeepReadonly<OtpParamsAcceptance> {
      return otpParamsAcceptance(this.$data.$state)
    },
    otpParamsAcceptanceInProgress (): boolean {
      return hasIndicator(this.otpParamsAcceptance)
    },
    activationOtpIcon (): string {
      return this.$v.activation.correct ? '' : 'error'
    },
    otpReset (): DeepReadonly<OtpReset> {
      return otpReset(this.$data.$state)
    },
    otpResetInProgress (): boolean {
      return hasIndicator(this.otpReset)
    },
    deactivationOtpIcon (): string {
      return this.$v.deactivation.correct ? '' : 'error'
    }
  },
  created () {
    this.$data.$actions.pipe(
      filter(isActionSuccess(otpParamsAcceptanceSignal)),
      takeUntil(this.$data.$destruction)
    ).subscribe(() => {
      this.activation.otp = ''
      this.activation.untouchedSinceDispatch = false
      this.$v.activation.$reset()
      this.dispatch(otpParamsGenerationReset())
      this.dispatch(otpParamsAcceptanceReset())
      this.dispatch(showToast({ message: this.$t('DONE') as string }))
    })
    this.$data.$actions.pipe(
      filter(isActionSuccess(otpResetSignal)),
      takeUntil(this.$data.$destruction)
    ).subscribe(() => {
      this.deactivation.otp = ''
      this.deactivation.untouchedSinceDispatch = false
      this.$v.deactivation.$reset()
      this.dispatch(cancelOtpReset())
      this.dispatch(showToast({ message: this.$t('DONE') as string }))
    })
  },
  methods: {
    generate () {
      this.dispatch(generateOtpParams())
    },
    setActivationOtp (value: string) {
      this.activation.otp = value
      this.activation.untouchedSinceDispatch = false
    },
    activate () {
      if (!this.otpParamsAcceptanceInProgress) {
        this.$v.activation.$touch()
        if (!this.$v.activation.$invalid) {
          this.activation.untouchedSinceDispatch = true
          if (!this.maybeOtpParams) {
            throw new Error('`otpParamsGeneration` has no data')
          }
          this.dispatch(acceptOtpParams({
            otpParamsId: this.maybeOtpParams.otpParamsId,
            otp: this.activation.otp
          }))
        }
      }
    },
    setDeactivationOtp (value: string) {
      this.deactivation.otp = value
      this.deactivation.untouchedSinceDispatch = false
    },
    deactivate () {
      if (!this.otpResetInProgress) {
        this.$v.deactivation.$touch()
        if (!this.$v.deactivation.$invalid) {
          this.deactivation.untouchedSinceDispatch = true
          this.dispatch(resetOtp({
            otp: this.deactivation.otp
          }))
        }
      }
    }
  },
  beforeDestroy () {
    this.dispatch(otpParamsGenerationReset())
    this.dispatch(otpParamsAcceptanceReset())
    this.dispatch(cancelOtpReset())
  }
})
