






























































import Vue from 'vue'
import { canAccessApi } from '@/redux/modules/user/account/selectors'

export default Vue.extend({
  props: ['value'],
  computed: {
    canAccessApi (): boolean {
      return canAccessApi(this.$data.$state)
    },
    isTemporary (): boolean {
      return this.$vuetify.breakpoint.xsOnly
    },
    isPermanent (): boolean {
      return !this.isTemporary
    }
  },
  methods: {
    input (value: boolean) {
      this.$emit('input', value)
    }
  }
})
