


















































import FileSaver from 'file-saver'
import { filter, takeUntil } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import Vue from 'vue'
import { isSignalFailure, isSignalSuccess, isSignalFinale } from '@/redux/flow_signal'
import { showToast } from '@/redux/modules/ui/toast/actions'
import { export_, exportSignal, ExportError } from '@/redux/modules/user/keys/actions'
import { serializeVault } from './csv'

export default Vue.extend({
  data () {
    return {
      ack: false,
      password: '',
      inProgress: false
    }
  },
  created () {
    this.$data.$actions.pipe(
      filter(isActionOf(exportSignal)),
      takeUntil(this.$data.$destruction)
    ).subscribe((action: ReturnType<typeof exportSignal>) => {
      if (isSignalFinale(action.payload)) {
        this.inProgress = false
      }
      if (isSignalFailure(action.payload)) {
        this.dispatch(showToast({
          message: this.messageByError(action.payload.error.value)
        }))
        return
      }
      if (isSignalSuccess(action.payload)) {
        const blob = new Blob(
          [serializeVault(action.payload.data)],
          { type: 'text/csv' }
        )
        FileSaver.saveAs(blob, `vault_${new Date().toISOString()}.csv`)
        this.password = ''
        this.ack = false
      }
    })
  },
  computed: {
    step () {
      let step = 0
      if (this.ack) {
        step += 1
      }
      return step
    },
    formHeight () {
      return 42
    }
  },
  methods: {
    download () {
      this.inProgress = true
      this.dispatch(export_({ password: this.password }))
    },
    messageByError (error: ExportError): string {
      switch (error) {
        case ExportError.INVALID_PASSWORD:
          return this.$t('INVALID_PASSWORD') as string
        default:
          return 'Unable to export'
      }
    }
  }
})
