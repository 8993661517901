/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * proto/service.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ChangeMasterKeyRequestRenewal
 */
export interface ChangeMasterKeyRequestRenewal {
    /**
     * 
     * @type {string}
     * @memberof ChangeMasterKeyRequestRenewal
     */
    salt?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeMasterKeyRequestRenewal
     */
    digest?: string;
    /**
     * 
     * @type {Array<ServiceKeyPatch>}
     * @memberof ChangeMasterKeyRequestRenewal
     */
    keys?: Array<ServiceKeyPatch>;
}

/**
 * 
 * @export
 * @interface GetRecentSessionsResponseSession
 */
export interface GetRecentSessionsResponseSession {
    /**
     * 
     * @type {string}
     * @memberof GetRecentSessionsResponseSession
     */
    creationTimeInMillis?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRecentSessionsResponseSession
     */
    ipAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRecentSessionsResponseSession
     */
    userAgent?: string;
    /**
     * 
     * @type {ServiceGeolocation}
     * @memberof GetRecentSessionsResponseSession
     */
    geolocation?: ServiceGeolocation;
    /**
     * 
     * @type {GetRecentSessionsResponseSessionStatus}
     * @memberof GetRecentSessionsResponseSession
     */
    status?: GetRecentSessionsResponseSessionStatus;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum GetRecentSessionsResponseSessionStatus {
    UNKNOWNSTATUS = <any> 'UNKNOWN_STATUS',
    AWAITING2FA = <any> 'AWAITING_2FA',
    ACTIVATED = <any> 'ACTIVATED',
    DISABLED = <any> 'DISABLED'
}

/**
 * 
 * @export
 * @interface GooglerpcStatus
 */
export interface GooglerpcStatus {
    /**
     * 
     * @type {number}
     * @memberof GooglerpcStatus
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof GooglerpcStatus
     */
    message?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof GooglerpcStatus
     */
    details?: Array<ProtobufAny>;
}

/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    typeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    value?: string;
}

/**
 * 
 * @export
 * @interface ServiceAcceptOtpParamsRequest
 */
export interface ServiceAcceptOtpParamsRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceAcceptOtpParamsRequest
     */
    otpParamsId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAcceptOtpParamsRequest
     */
    otp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceAcceptOtpParamsRequest
     */
    yieldTrustedToken?: boolean;
}

/**
 * 
 * @export
 * @interface ServiceAcceptOtpParamsResponse
 */
export interface ServiceAcceptOtpParamsResponse {
    /**
     * 
     * @type {ServiceAcceptOtpParamsResponseError}
     * @memberof ServiceAcceptOtpParamsResponse
     */
    error?: ServiceAcceptOtpParamsResponseError;
    /**
     * 
     * @type {string}
     * @memberof ServiceAcceptOtpParamsResponse
     */
    trustedToken?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceAcceptOtpParamsResponseError {
    NONE = <any> 'NONE',
    INVALIDCODE = <any> 'INVALID_CODE'
}

/**
 * 
 * @export
 * @interface ServiceAckFeaturePromptRequest
 */
export interface ServiceAckFeaturePromptRequest {
    /**
     * 
     * @type {ServiceFeatureType}
     * @memberof ServiceAckFeaturePromptRequest
     */
    featureType?: ServiceFeatureType;
}

/**
 * 
 * @export
 * @interface ServiceAckFeaturePromptResponse
 */
export interface ServiceAckFeaturePromptResponse {
}

/**
 * 
 * @export
 * @interface ServiceAcquireMailTokenRequest
 */
export interface ServiceAcquireMailTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceAcquireMailTokenRequest
     */
    digest?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAcquireMailTokenRequest
     */
    mail?: string;
}

/**
 * 
 * @export
 * @interface ServiceAcquireMailTokenResponse
 */
export interface ServiceAcquireMailTokenResponse {
    /**
     * 
     * @type {ServiceAcquireMailTokenResponseError}
     * @memberof ServiceAcquireMailTokenResponse
     */
    error?: ServiceAcquireMailTokenResponseError;
    /**
     * 
     * @type {string}
     * @memberof ServiceAcquireMailTokenResponse
     */
    tokenId?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceAcquireMailTokenResponseError {
    NONE = <any> 'NONE',
    INVALIDDIGEST = <any> 'INVALID_DIGEST'
}

/**
 * 
 * @export
 * @interface ServiceChangeMasterKeyRequest
 */
export interface ServiceChangeMasterKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceChangeMasterKeyRequest
     */
    currentDigest?: string;
    /**
     * 
     * @type {ChangeMasterKeyRequestRenewal}
     * @memberof ServiceChangeMasterKeyRequest
     */
    renewal?: ChangeMasterKeyRequestRenewal;
}

/**
 * 
 * @export
 * @interface ServiceChangeMasterKeyResponse
 */
export interface ServiceChangeMasterKeyResponse {
    /**
     * 
     * @type {ServiceChangeMasterKeyResponseError}
     * @memberof ServiceChangeMasterKeyResponse
     */
    error?: ServiceChangeMasterKeyResponseError;
    /**
     * 
     * @type {string}
     * @memberof ServiceChangeMasterKeyResponse
     */
    sessionKey?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceChangeMasterKeyResponseError {
    NONE = <any> 'NONE',
    INVALIDCURRENTDIGEST = <any> 'INVALID_CURRENT_DIGEST'
}

/**
 * 
 * @export
 * @interface ServiceChangeUsernameRequest
 */
export interface ServiceChangeUsernameRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceChangeUsernameRequest
     */
    digest?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceChangeUsernameRequest
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface ServiceChangeUsernameResponse
 */
export interface ServiceChangeUsernameResponse {
    /**
     * 
     * @type {ServiceChangeUsernameResponseError}
     * @memberof ServiceChangeUsernameResponse
     */
    error?: ServiceChangeUsernameResponseError;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceChangeUsernameResponseError {
    NONE = <any> 'NONE',
    INVALIDDIGEST = <any> 'INVALID_DIGEST',
    NAMETAKEN = <any> 'NAME_TAKEN'
}

/**
 * 
 * @export
 * @interface ServiceCreateKeyRequest
 */
export interface ServiceCreateKeyRequest {
    /**
     * 
     * @type {ServicePassword}
     * @memberof ServiceCreateKeyRequest
     */
    password?: ServicePassword;
    /**
     * 
     * @type {ServiceKeyAttrs}
     * @memberof ServiceCreateKeyRequest
     */
    attrs?: ServiceKeyAttrs;
}

/**
 * 
 * @export
 * @interface ServiceCreateKeyResponse
 */
export interface ServiceCreateKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceCreateKeyResponse
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceCreateKeyResponse
     */
    creationTimeInMillis?: string;
}

/**
 * 
 * @export
 * @interface ServiceDeleteAccountRequest
 */
export interface ServiceDeleteAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceDeleteAccountRequest
     */
    digest?: string;
}

/**
 * 
 * @export
 * @interface ServiceDeleteAccountResponse
 */
export interface ServiceDeleteAccountResponse {
    /**
     * 
     * @type {ServiceDeleteAccountResponseError}
     * @memberof ServiceDeleteAccountResponse
     */
    error?: ServiceDeleteAccountResponseError;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceDeleteAccountResponseError {
    NONE = <any> 'NONE',
    INVALIDDIGEST = <any> 'INVALID_DIGEST'
}

/**
 * 
 * @export
 * @interface ServiceDeleteKeyRequest
 */
export interface ServiceDeleteKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceDeleteKeyRequest
     */
    identifier?: string;
}

/**
 * 
 * @export
 * @interface ServiceDeleteKeyResponse
 */
export interface ServiceDeleteKeyResponse {
}

/**
 * 
 * @export
 * @interface ServiceElectShadowRequest
 */
export interface ServiceElectShadowRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceElectShadowRequest
     */
    identifier?: string;
}

/**
 * 
 * @export
 * @interface ServiceElectShadowResponse
 */
export interface ServiceElectShadowResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceElectShadowResponse
     */
    parent?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceElectShadowResponse
     */
    deletedShadows?: Array<string>;
}

/**
 * 
 * @export
 * @interface ServiceFeaturePrompt
 */
export interface ServiceFeaturePrompt {
    /**
     * 
     * @type {ServiceFeatureType}
     * @memberof ServiceFeaturePrompt
     */
    featureType?: ServiceFeatureType;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceFeatureType {
    UNKNOWN = <any> 'UNKNOWN',
    RELEASE = <any> 'RELEASE'
}

/**
 * 
 * @export
 * @interface ServiceGenerateOtpParamsRequest
 */
export interface ServiceGenerateOtpParamsRequest {
}

/**
 * 
 * @export
 * @interface ServiceGenerateOtpParamsResponse
 */
export interface ServiceGenerateOtpParamsResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceGenerateOtpParamsResponse
     */
    otpParamsId?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceGenerateOtpParamsResponse
     */
    sharedSecret?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceGenerateOtpParamsResponse
     */
    scratchCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceGenerateOtpParamsResponse
     */
    keyUri?: string;
}

/**
 * 
 * @export
 * @interface ServiceGeolocation
 */
export interface ServiceGeolocation {
    /**
     * 
     * @type {string}
     * @memberof ServiceGeolocation
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceGeolocation
     */
    city?: string;
}

/**
 * 
 * @export
 * @interface ServiceGetRecentSessionsResponse
 */
export interface ServiceGetRecentSessionsResponse {
    /**
     * 
     * @type {Array<GetRecentSessionsResponseSession>}
     * @memberof ServiceGetRecentSessionsResponse
     */
    sessions?: Array<GetRecentSessionsResponseSession>;
}

/**
 * 
 * @export
 * @interface ServiceGetSaltRequest
 */
export interface ServiceGetSaltRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceGetSaltRequest
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface ServiceGetSaltResponse
 */
export interface ServiceGetSaltResponse {
    /**
     * 
     * @type {ServiceGetSaltResponseError}
     * @memberof ServiceGetSaltResponse
     */
    error?: ServiceGetSaltResponseError;
    /**
     * 
     * @type {string}
     * @memberof ServiceGetSaltResponse
     */
    salt?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceGetSaltResponseError {
    NONE = <any> 'NONE',
    NOTFOUND = <any> 'NOT_FOUND'
}

/**
 * 
 * @export
 * @interface ServiceImportKeysRequest
 */
export interface ServiceImportKeysRequest {
    /**
     * 
     * @type {Array<ServicePassword>}
     * @memberof ServiceImportKeysRequest
     */
    passwords?: Array<ServicePassword>;
}

/**
 * 
 * @export
 * @interface ServiceImportKeysResponse
 */
export interface ServiceImportKeysResponse {
    /**
     * 
     * @type {Array<ServiceKeyProto>}
     * @memberof ServiceImportKeysResponse
     */
    keys?: Array<ServiceKeyProto>;
}

/**
 * 
 * @export
 * @interface ServiceKeepAliveRequest
 */
export interface ServiceKeepAliveRequest {
}

/**
 * 
 * @export
 * @interface ServiceKeepAliveResponse
 */
export interface ServiceKeepAliveResponse {
}

/**
 * 
 * @export
 * @interface ServiceKeyAttrs
 */
export interface ServiceKeyAttrs {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceKeyAttrs
     */
    isShadow?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceKeyAttrs
     */
    parent?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceKeyAttrs
     */
    isPinned?: boolean;
}

/**
 * 
 * @export
 * @interface ServiceKeyPatch
 */
export interface ServiceKeyPatch {
    /**
     * 
     * @type {string}
     * @memberof ServiceKeyPatch
     */
    identifier?: string;
    /**
     * 
     * @type {ServicePassword}
     * @memberof ServiceKeyPatch
     */
    password?: ServicePassword;
}

/**
 * 
 * @export
 * @interface ServiceKeyProto
 */
export interface ServiceKeyProto {
    /**
     * 
     * @type {string}
     * @memberof ServiceKeyProto
     */
    identifier?: string;
    /**
     * 
     * @type {ServicePassword}
     * @memberof ServiceKeyProto
     */
    password?: ServicePassword;
    /**
     * 
     * @type {ServiceKeyAttrs}
     * @memberof ServiceKeyProto
     */
    attrs?: ServiceKeyAttrs;
    /**
     * 
     * @type {string}
     * @memberof ServiceKeyProto
     */
    creationTimeInMillis?: string;
}

/**
 * 
 * @export
 * @interface ServiceLogInRequest
 */
export interface ServiceLogInRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceLogInRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLogInRequest
     */
    digest?: string;
}

/**
 * 
 * @export
 * @interface ServiceLogInResponse
 */
export interface ServiceLogInResponse {
    /**
     * 
     * @type {ServiceLogInResponseError}
     * @memberof ServiceLogInResponse
     */
    error?: ServiceLogInResponseError;
    /**
     * 
     * @type {ServiceUserData}
     * @memberof ServiceLogInResponse
     */
    userData?: ServiceUserData;
    /**
     * 
     * @type {ServiceOtpContext}
     * @memberof ServiceLogInResponse
     */
    otpContext?: ServiceOtpContext;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceLogInResponseError {
    NONE = <any> 'NONE',
    INVALIDCREDENTIALS = <any> 'INVALID_CREDENTIALS'
}

/**
 * 
 * @export
 * @interface ServiceMailVerification
 */
export interface ServiceMailVerification {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceMailVerification
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceMailVerification
     */
    tokenId?: string;
}

/**
 * 
 * @export
 * @interface ServiceOtpContext
 */
export interface ServiceOtpContext {
    /**
     * 
     * @type {string}
     * @memberof ServiceOtpContext
     */
    authnKey?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceOtpContext
     */
    attemptsLeft?: number;
}

/**
 * 
 * @export
 * @interface ServicePassword
 */
export interface ServicePassword {
    /**
     * 
     * @type {string}
     * @memberof ServicePassword
     */
    value?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServicePassword
     */
    tags?: Array<string>;
}

/**
 * 
 * @export
 * @interface ServiceProvideOtpRequest
 */
export interface ServiceProvideOtpRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceProvideOtpRequest
     */
    authnKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvideOtpRequest
     */
    otp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceProvideOtpRequest
     */
    yieldTrustedToken?: boolean;
}

/**
 * 
 * @export
 * @interface ServiceProvideOtpResponse
 */
export interface ServiceProvideOtpResponse {
    /**
     * 
     * @type {ServiceProvideOtpResponseError}
     * @memberof ServiceProvideOtpResponse
     */
    error?: ServiceProvideOtpResponseError;
    /**
     * 
     * @type {number}
     * @memberof ServiceProvideOtpResponse
     */
    attemptsLeft?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvideOtpResponse
     */
    trustedToken?: string;
    /**
     * 
     * @type {ServiceUserData}
     * @memberof ServiceProvideOtpResponse
     */
    userData?: ServiceUserData;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceProvideOtpResponseError {
    NONE = <any> 'NONE',
    INVALIDCODE = <any> 'INVALID_CODE',
    ATTEMPTSEXHAUSTED = <any> 'ATTEMPTS_EXHAUSTED'
}

/**
 * 
 * @export
 * @interface ServiceReadKeysResponse
 */
export interface ServiceReadKeysResponse {
    /**
     * 
     * @type {Array<ServiceKeyProto>}
     * @memberof ServiceReadKeysResponse
     */
    keys?: Array<ServiceKeyProto>;
}

/**
 * 
 * @export
 * @interface ServiceRegisterRequest
 */
export interface ServiceRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceRegisterRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceRegisterRequest
     */
    salt?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceRegisterRequest
     */
    digest?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceRegisterRequest
     */
    mail?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceRegisterRequest
     */
    captchaToken?: string;
}

/**
 * 
 * @export
 * @interface ServiceRegisterResponse
 */
export interface ServiceRegisterResponse {
    /**
     * 
     * @type {ServiceRegisterResponseError}
     * @memberof ServiceRegisterResponse
     */
    error?: ServiceRegisterResponseError;
    /**
     * 
     * @type {string}
     * @memberof ServiceRegisterResponse
     */
    sessionKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceRegisterResponse
     */
    mailTokenId?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceRegisterResponseError {
    NONE = <any> 'NONE',
    NAMETAKEN = <any> 'NAME_TAKEN'
}

/**
 * 
 * @export
 * @interface ServiceReleaseMailTokenRequest
 */
export interface ServiceReleaseMailTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceReleaseMailTokenRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceReleaseMailTokenRequest
     */
    tokenId?: string;
}

/**
 * 
 * @export
 * @interface ServiceReleaseMailTokenResponse
 */
export interface ServiceReleaseMailTokenResponse {
    /**
     * 
     * @type {ServiceReleaseMailTokenResponseError}
     * @memberof ServiceReleaseMailTokenResponse
     */
    error?: ServiceReleaseMailTokenResponseError;
    /**
     * 
     * @type {string}
     * @memberof ServiceReleaseMailTokenResponse
     */
    mail?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceReleaseMailTokenResponseError {
    NONE = <any> 'NONE',
    INVALIDCODE = <any> 'INVALID_CODE',
    INVALIDTOKENID = <any> 'INVALID_TOKEN_ID',
    TOOMANYREQUESTS = <any> 'TOO_MANY_REQUESTS'
}

/**
 * 
 * @export
 * @interface ServiceResetOtpRequest
 */
export interface ServiceResetOtpRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceResetOtpRequest
     */
    otp?: string;
}

/**
 * 
 * @export
 * @interface ServiceResetOtpResponse
 */
export interface ServiceResetOtpResponse {
    /**
     * 
     * @type {ServiceResetOtpResponseError}
     * @memberof ServiceResetOtpResponse
     */
    error?: ServiceResetOtpResponseError;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceResetOtpResponseError {
    NONE = <any> 'NONE',
    INVALIDCODE = <any> 'INVALID_CODE'
}

/**
 * 
 * @export
 * @interface ServiceTogglePinRequest
 */
export interface ServiceTogglePinRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceTogglePinRequest
     */
    identifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceTogglePinRequest
     */
    isPinned?: boolean;
}

/**
 * 
 * @export
 * @interface ServiceTogglePinResponse
 */
export interface ServiceTogglePinResponse {
}

/**
 * 
 * @export
 * @interface ServiceUpdateKeyRequest
 */
export interface ServiceUpdateKeyRequest {
    /**
     * 
     * @type {ServiceKeyPatch}
     * @memberof ServiceUpdateKeyRequest
     */
    key?: ServiceKeyPatch;
}

/**
 * 
 * @export
 * @interface ServiceUpdateKeyResponse
 */
export interface ServiceUpdateKeyResponse {
}

/**
 * 
 * @export
 * @interface ServiceUserData
 */
export interface ServiceUserData {
    /**
     * 
     * @type {string}
     * @memberof ServiceUserData
     */
    sessionKey?: string;
    /**
     * 
     * @type {Array<ServiceFeaturePrompt>}
     * @memberof ServiceUserData
     */
    featurePrompts?: Array<ServiceFeaturePrompt>;
    /**
     * 
     * @type {Array<ServiceKeyProto>}
     * @memberof ServiceUserData
     */
    userKeys?: Array<ServiceKeyProto>;
    /**
     * 
     * @type {ServiceMailVerification}
     * @memberof ServiceUserData
     */
    mailVerification?: ServiceMailVerification;
    /**
     * 
     * @type {string}
     * @memberof ServiceUserData
     */
    mail?: string;
}


/**
 * AdministrationApi - fetch parameter creator
 * @export
 */
export const AdministrationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ServiceAcceptOtpParamsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAcceptOtpParams(body: ServiceAcceptOtpParamsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationAcceptOtpParams.');
            }
            const localVarPath = `/administration/accept-otp-params`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceAcceptOtpParamsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceAckFeaturePromptRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAckFeaturePrompt(body: ServiceAckFeaturePromptRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationAckFeaturePrompt.');
            }
            const localVarPath = `/administration/ack-feature-prompt`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceAckFeaturePromptRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceAcquireMailTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAcquireMailToken(body: ServiceAcquireMailTokenRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationAcquireMailToken.');
            }
            const localVarPath = `/administration/acquire-mail-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceAcquireMailTokenRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceChangeMasterKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationChangeMasterKey(body: ServiceChangeMasterKeyRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationChangeMasterKey.');
            }
            const localVarPath = `/administration/change-master-key`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceChangeMasterKeyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceChangeUsernameRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationChangeUsername(body: ServiceChangeUsernameRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationChangeUsername.');
            }
            const localVarPath = `/administration/change-username`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceChangeUsernameRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceCreateKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationCreateKey(body: ServiceCreateKeyRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationCreateKey.');
            }
            const localVarPath = `/administration/create-key`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceCreateKeyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceDeleteAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationDeleteAccount(body: ServiceDeleteAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationDeleteAccount.');
            }
            const localVarPath = `/administration/delete-account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceDeleteAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceDeleteKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationDeleteKey(body: ServiceDeleteKeyRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationDeleteKey.');
            }
            const localVarPath = `/administration/delete-key`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceDeleteKeyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceElectShadowRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationElectShadow(body: ServiceElectShadowRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationElectShadow.');
            }
            const localVarPath = `/administration/elect-shadow`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceElectShadowRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceGenerateOtpParamsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationGenerateOtpParams(body: ServiceGenerateOtpParamsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationGenerateOtpParams.');
            }
            const localVarPath = `/administration/generate-otp-params`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceGenerateOtpParamsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationGetRecentSessions(options: any = {}): FetchArgs {
            const localVarPath = `/administration/get-recent-sessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceImportKeysRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationImportKeys(body: ServiceImportKeysRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationImportKeys.');
            }
            const localVarPath = `/administration/import-keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceImportKeysRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceKeepAliveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationKeepAlive(body: ServiceKeepAliveRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationKeepAlive.');
            }
            const localVarPath = `/administration/keep-alive`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceKeepAliveRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationReadKeys(options: any = {}): FetchArgs {
            const localVarPath = `/administration/read-keys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceReleaseMailTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationReleaseMailToken(body: ServiceReleaseMailTokenRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationReleaseMailToken.');
            }
            const localVarPath = `/administration/release-mail-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceReleaseMailTokenRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceResetOtpRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationResetOtp(body: ServiceResetOtpRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationResetOtp.');
            }
            const localVarPath = `/administration/reset-otp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceResetOtpRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceTogglePinRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationTogglePin(body: ServiceTogglePinRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationTogglePin.');
            }
            const localVarPath = `/administration/toggle-pin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceTogglePinRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceUpdateKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationUpdateKey(body: ServiceUpdateKeyRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling administrationUpdateKey.');
            }
            const localVarPath = `/administration/update-key`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceUpdateKeyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdministrationApi - functional programming interface
 * @export
 */
export const AdministrationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ServiceAcceptOtpParamsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAcceptOtpParams(body: ServiceAcceptOtpParamsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceAcceptOtpParamsResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationAcceptOtpParams(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceAckFeaturePromptRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAckFeaturePrompt(body: ServiceAckFeaturePromptRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceAckFeaturePromptResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationAckFeaturePrompt(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceAcquireMailTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAcquireMailToken(body: ServiceAcquireMailTokenRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceAcquireMailTokenResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationAcquireMailToken(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceChangeMasterKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationChangeMasterKey(body: ServiceChangeMasterKeyRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceChangeMasterKeyResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationChangeMasterKey(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceChangeUsernameRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationChangeUsername(body: ServiceChangeUsernameRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceChangeUsernameResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationChangeUsername(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceCreateKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationCreateKey(body: ServiceCreateKeyRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceCreateKeyResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationCreateKey(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceDeleteAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationDeleteAccount(body: ServiceDeleteAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceDeleteAccountResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationDeleteAccount(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceDeleteKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationDeleteKey(body: ServiceDeleteKeyRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceDeleteKeyResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationDeleteKey(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceElectShadowRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationElectShadow(body: ServiceElectShadowRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceElectShadowResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationElectShadow(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceGenerateOtpParamsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationGenerateOtpParams(body: ServiceGenerateOtpParamsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceGenerateOtpParamsResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationGenerateOtpParams(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationGetRecentSessions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceGetRecentSessionsResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationGetRecentSessions(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceImportKeysRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationImportKeys(body: ServiceImportKeysRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceImportKeysResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationImportKeys(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceKeepAliveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationKeepAlive(body: ServiceKeepAliveRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceKeepAliveResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationKeepAlive(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationReadKeys(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceReadKeysResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationReadKeys(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceReleaseMailTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationReleaseMailToken(body: ServiceReleaseMailTokenRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceReleaseMailTokenResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationReleaseMailToken(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceResetOtpRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationResetOtp(body: ServiceResetOtpRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceResetOtpResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationResetOtp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceTogglePinRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationTogglePin(body: ServiceTogglePinRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceTogglePinResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationTogglePin(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceUpdateKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationUpdateKey(body: ServiceUpdateKeyRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceUpdateKeyResponse> {
            const localVarFetchArgs = AdministrationApiFetchParamCreator(configuration).administrationUpdateKey(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdministrationApi - factory interface
 * @export
 */
export const AdministrationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ServiceAcceptOtpParamsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAcceptOtpParams(body: ServiceAcceptOtpParamsRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationAcceptOtpParams(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceAckFeaturePromptRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAckFeaturePrompt(body: ServiceAckFeaturePromptRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationAckFeaturePrompt(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceAcquireMailTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationAcquireMailToken(body: ServiceAcquireMailTokenRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationAcquireMailToken(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceChangeMasterKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationChangeMasterKey(body: ServiceChangeMasterKeyRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationChangeMasterKey(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceChangeUsernameRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationChangeUsername(body: ServiceChangeUsernameRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationChangeUsername(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceCreateKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationCreateKey(body: ServiceCreateKeyRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationCreateKey(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceDeleteAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationDeleteAccount(body: ServiceDeleteAccountRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationDeleteAccount(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceDeleteKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationDeleteKey(body: ServiceDeleteKeyRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationDeleteKey(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceElectShadowRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationElectShadow(body: ServiceElectShadowRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationElectShadow(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceGenerateOtpParamsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationGenerateOtpParams(body: ServiceGenerateOtpParamsRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationGenerateOtpParams(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationGetRecentSessions(options?: any) {
            return AdministrationApiFp(configuration).administrationGetRecentSessions(options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceImportKeysRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationImportKeys(body: ServiceImportKeysRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationImportKeys(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceKeepAliveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationKeepAlive(body: ServiceKeepAliveRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationKeepAlive(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationReadKeys(options?: any) {
            return AdministrationApiFp(configuration).administrationReadKeys(options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceReleaseMailTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationReleaseMailToken(body: ServiceReleaseMailTokenRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationReleaseMailToken(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceResetOtpRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationResetOtp(body: ServiceResetOtpRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationResetOtp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceTogglePinRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationTogglePin(body: ServiceTogglePinRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationTogglePin(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceUpdateKeyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrationUpdateKey(body: ServiceUpdateKeyRequest, options?: any) {
            return AdministrationApiFp(configuration).administrationUpdateKey(body, options)(fetch, basePath);
        },
    };
};

/**
 * AdministrationApi - object-oriented interface
 * @export
 * @class AdministrationApi
 * @extends {BaseAPI}
 */
export class AdministrationApi extends BaseAPI {
    /**
     * 
     * @param {ServiceAcceptOtpParamsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationAcceptOtpParams(body: ServiceAcceptOtpParamsRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationAcceptOtpParams(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceAckFeaturePromptRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationAckFeaturePrompt(body: ServiceAckFeaturePromptRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationAckFeaturePrompt(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceAcquireMailTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationAcquireMailToken(body: ServiceAcquireMailTokenRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationAcquireMailToken(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceChangeMasterKeyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationChangeMasterKey(body: ServiceChangeMasterKeyRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationChangeMasterKey(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceChangeUsernameRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationChangeUsername(body: ServiceChangeUsernameRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationChangeUsername(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceCreateKeyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationCreateKey(body: ServiceCreateKeyRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationCreateKey(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceDeleteAccountRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationDeleteAccount(body: ServiceDeleteAccountRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationDeleteAccount(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceDeleteKeyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationDeleteKey(body: ServiceDeleteKeyRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationDeleteKey(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceElectShadowRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationElectShadow(body: ServiceElectShadowRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationElectShadow(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceGenerateOtpParamsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationGenerateOtpParams(body: ServiceGenerateOtpParamsRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationGenerateOtpParams(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationGetRecentSessions(options?: any) {
        return AdministrationApiFp(this.configuration).administrationGetRecentSessions(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceImportKeysRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationImportKeys(body: ServiceImportKeysRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationImportKeys(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceKeepAliveRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationKeepAlive(body: ServiceKeepAliveRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationKeepAlive(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationReadKeys(options?: any) {
        return AdministrationApiFp(this.configuration).administrationReadKeys(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceReleaseMailTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationReleaseMailToken(body: ServiceReleaseMailTokenRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationReleaseMailToken(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceResetOtpRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationResetOtp(body: ServiceResetOtpRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationResetOtp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceTogglePinRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationTogglePin(body: ServiceTogglePinRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationTogglePin(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceUpdateKeyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrationApi
     */
    public administrationUpdateKey(body: ServiceUpdateKeyRequest, options?: any) {
        return AdministrationApiFp(this.configuration).administrationUpdateKey(body, options)(this.fetch, this.basePath);
    }

}

/**
 * AuthenticationApi - fetch parameter creator
 * @export
 */
export const AuthenticationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ServiceGetSaltRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationFetchSalt(body: ServiceGetSaltRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authenticationFetchSalt.');
            }
            const localVarPath = `/authentication/fetch-salt`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceGetSaltRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceLogInRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogIn(body: ServiceLogInRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authenticationLogIn.');
            }
            const localVarPath = `/authentication/log-in`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceLogInRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceProvideOtpRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationProvideOtp(body: ServiceProvideOtpRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authenticationProvideOtp.');
            }
            const localVarPath = `/authentication/provide-otp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceProvideOtpRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceRegisterRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRegister(body: ServiceRegisterRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authenticationRegister.');
            }
            const localVarPath = `/authentication/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ServiceRegisterRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ServiceGetSaltRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationFetchSalt(body: ServiceGetSaltRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceGetSaltResponse> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).authenticationFetchSalt(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceLogInRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogIn(body: ServiceLogInRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceLogInResponse> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).authenticationLogIn(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceProvideOtpRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationProvideOtp(body: ServiceProvideOtpRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceProvideOtpResponse> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).authenticationProvideOtp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ServiceRegisterRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRegister(body: ServiceRegisterRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ServiceRegisterResponse> {
            const localVarFetchArgs = AuthenticationApiFetchParamCreator(configuration).authenticationRegister(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {ServiceGetSaltRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationFetchSalt(body: ServiceGetSaltRequest, options?: any) {
            return AuthenticationApiFp(configuration).authenticationFetchSalt(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceLogInRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogIn(body: ServiceLogInRequest, options?: any) {
            return AuthenticationApiFp(configuration).authenticationLogIn(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceProvideOtpRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationProvideOtp(body: ServiceProvideOtpRequest, options?: any) {
            return AuthenticationApiFp(configuration).authenticationProvideOtp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ServiceRegisterRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRegister(body: ServiceRegisterRequest, options?: any) {
            return AuthenticationApiFp(configuration).authenticationRegister(body, options)(fetch, basePath);
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {ServiceGetSaltRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationFetchSalt(body: ServiceGetSaltRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationFetchSalt(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceLogInRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLogIn(body: ServiceLogInRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLogIn(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceProvideOtpRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationProvideOtp(body: ServiceProvideOtpRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationProvideOtp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ServiceRegisterRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationRegister(body: ServiceRegisterRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationRegister(body, options)(this.fetch, this.basePath);
    }

}

