























































import Vue, { VueConstructor } from 'vue'
import { Framework } from 'vuetify'
import { sessionUsername } from '@/redux/modules/session/selectors'
import { isAuthenticated } from '@/redux/modules/user/account/selectors'
import { logOut, LogoutTrigger } from '@/redux/modules/user/account/actions'

interface Mixins {
  value: boolean;
}

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  props: ['value', 'hasMenu', 'extended'],
  computed: {
    isAuthenticated () {
      return isAuthenticated(this.$data.$state)
    },
    username () {
      return sessionUsername(this.$data.$state)
    },
    homeTarget (): string {
      return this.isAuthenticated ? '/dashboard' : '/'
    },
    clipLeft (): boolean {
      return this.hasMenu && (this.$vuetify as Framework).breakpoint.smAndUp
    },
    showNavIcon (): boolean {
      return this.hasMenu && (this.$vuetify as Framework).breakpoint.xsOnly
    }
  },
  methods: {
    toggle () {
      this.$emit('input', !this.value)
    },
    logOut () {
      this.dispatch(logOut(LogoutTrigger.USER_REQUEST))
    }
  }
})
