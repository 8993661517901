




































































































import { option, function as fn, map, eq } from 'fp-ts'
import { takeUntil, filter } from 'rxjs/operators'
import { DeepReadonly } from 'ts-essentials'
import Vue, { VueConstructor } from 'vue'
import { email, required, sameAs } from 'vuelidate/lib/validators'
import { ServiceRegisterResponseError } from '@/api/definitions'
import Page from '@/components/Page.vue'
import StrengthScore from '@/components/StrengthScore.vue'
import { remoteDataErrorIndicator, checkUsername } from '@/components/form_validators'
import { Score, getStrengthTestService } from '@/cryptography/strength_test_service'
import { getFlags } from '@/flags'
import { register, RegistrationFlowIndicator, registrationReset, registrationSignal } from '@/redux/modules/authn/actions'
import { registration, Registration } from '@/redux/modules/authn/selectors'
import { showToast } from '@/redux/modules/ui/toast/actions'
import { isActionSuccess } from '@/redux/flow_signal'
import { getTurnstileApi } from '@/turnstile_di'

const usernameTakenIndicator = remoteDataErrorIndicator(ServiceRegisterResponseError.NAMETAKEN)

const INDICATOR_TO_MESSAGE = new Map<RegistrationFlowIndicator, string>([
  [RegistrationFlowIndicator.GENERATING_PARAMETRIZATION, 'Generating salt'],
  [RegistrationFlowIndicator.COMPUTING_MASTER_KEY_DERIVATIVES, 'Computing keys'],
  [RegistrationFlowIndicator.MAKING_REQUEST, 'Making request']
])

interface Mixins {
  username: { untouchedSinceDispatch: boolean };
  registration: DeepReadonly<Registration>;
}

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  components: {
    page: Page,
    strengthScore: StrengthScore
  },
  data () {
    return {
      turnstileWidgetId: '',
      turnstileToken: '',
      username: {
        value: '',
        untouchedSinceDispatch: false
      },
      password: '',
      repeat: '',
      mail: ''
    }
  },
  created () {
    this.$data.$actions.pipe(
      filter(isActionSuccess(registrationSignal)),
      takeUntil(this.$data.$destruction)
    ).subscribe(() => {
      this.$router.push('/mail-verification')
    })
  },
  validations: {
    username: {
      required ({ value }) {
        return value.trim().length > 0
      },
      matchesPattern ({ value }) {
        return checkUsername(value)
      },
      isAvailable () {
        return !usernameTakenIndicator(this.registration, this.username.untouchedSinceDispatch)
      }
    },
    password: { required },
    repeat: { sameAs: sameAs('password') },
    mail: { required, email }
  },
  computed: {
    passwordStrength (): Score {
      return getStrengthTestService().score(this.password, [
        this.username.value,
        this.mail
      ])
    },
    registration (): DeepReadonly<Registration> {
      return registration(this.$data.$state)
    },
    usernameErrors (): { [key: string]: boolean } {
      return {
        [this.$t('USERNAME_CANNOT_BE_EMPTY') as string]: !this.$v.username.required,
        [this.$t('USERNAME_PATTERN_MISMATCH') as string]: !this.$v.username.matchesPattern,
        [this.$t('USERNAME_IS_ALREADY_TAKEN') as string]: !this.$v.username.isAvailable
      }
    },
    passwordErrors (): { [key: string]: boolean } {
      return {
        [this.$t('PASSWORD_CANNOT_BE_EMPTY') as string]: !this.$v.password.required
      }
    },
    repeatErrors (): { [key: string]: boolean } {
      return {
        [this.$t('PASSWORDS_DO_NOT_MATCH') as string]: !this.$v.repeat.sameAs
      }
    },
    mailErrors (): { [key: string]: boolean } {
      return {
        [this.$t('EMAIL_ADDRESS_IS_REQUIRED') as string]: !this.$v.mail.required,
        [this.$t('EMAIL_ADDRESS_IS_INVALID') as string]: !this.$v.mail.email
      }
    },
    indicatorMessage (): string | null {
      return fn.pipe(
        this.registration.indicator,
        option.chain((indicator) => map.lookup(eq.eqStrict)(indicator, INDICATOR_TO_MESSAGE)),
        option.getOrElse<string | null>(() => null)
      )
    },
    hasIndicatorMessage (): boolean {
      return this.indicatorMessage !== null
    }
  },
  methods: {
    setUsername (value: string) {
      this.username.value = value
      this.username.untouchedSinceDispatch = false
    },
    submit () {
      if (this.hasIndicatorMessage) {
        return
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (this.turnstileToken === '') {
        this.dispatch(showToast({
          message: 'Please complete the CAPTCHA 🧮'
        }))
        return
      }
      this.username.untouchedSinceDispatch = true
      this.dispatch(register({
        username: this.username.value,
        password: this.password,
        mail: this.mail,
        captchaToken: this.turnstileToken
      }))
    },
    mountTurnstile () {
      const turnstileApi = getTurnstileApi()
      if (turnstileApi === null) {
        return
      }
      this.turnstileWidgetId = turnstileApi.render(
        this.$refs.captcha as HTMLElement,
        {
          sitekey: getFlags().turnstileSiteKey,
          action: 'register',
          callback: (token) => {
            this.turnstileToken = token
          },
          'expired-callback': () => {
            this.turnstileToken = ''
            turnstileApi.reset(this.turnstileWidgetId)
          },
          'response-field': false,
          size: 'normal'
        }
      )!
    }
  },
  mounted () {
    this.mountTurnstile()
  },
  beforeDestroy () {
    // Ideally we should call `unmountTurnstile` here,
    // but the element is removed from the DOM before.
    this.dispatch(registrationReset())
  }
})
