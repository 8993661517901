import { render, staticRenderFns } from "./MailVerification.vue?vue&type=template&id=44a4fe51&scoped=true"
import script from "./MailVerification.vue?vue&type=script&lang=ts"
export * from "./MailVerification.vue?vue&type=script&lang=ts"
import style0 from "./MailVerification.vue?vue&type=style&index=0&id=44a4fe51&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../pwa/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a4fe51",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../pwa/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VContainer,VForm,VMain,VRow,VStepper,VStepperContent,VStepperStep})
