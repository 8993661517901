




















import Vue from 'vue'
import Toast from '@/components/Toast.vue'
import Toolbar from '@/components/toolbar-with-menu/Toolbar.vue'

export default Vue.extend({
  props: ['hasMenu', 'showMenu', 'noBackground', 'toolbarIsExtended'],
  components: {
    toast: Toast,
    toolbar: Toolbar
  },
  computed: {
    appClasses (): { [key: string]: boolean } {
      return {
        'no-background': this.noBackground
      }
    }
  },
  methods: {
    menuSwitch (value: boolean) {
      this.$emit('menuSwitch', value)
    }
  }
})
