












import Vue from 'vue'
import { hideToast } from '@/redux/modules/ui/toast/actions'

export default Vue.extend({
  computed: {
    show () {
      return this.$data.$state.ui.toast.show
    },
    timeout () {
      return this.$data.$state.ui.toast.timeout
    },
    message () {
      return this.$data.$state.ui.toast.message
    }
  },
  methods: {
    setVisibility (value: boolean) {
      if (value === false) {
        this.dispatch(hideToast())
      } else {
        throw new Error('<v-snackbar> triggered `input(true)`')
      }
    }
  }
})
