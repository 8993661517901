



































































































































import { function as fn, option } from 'fp-ts'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { Subject, of, iif, timer, EMPTY, interval } from 'rxjs'
import { filter, mapTo, switchMap, takeUntil, take, tap } from 'rxjs/operators'
import { DeepReadonly } from 'ts-essentials'
import { isActionOf } from 'typesafe-actions'
import Vue, { PropType } from 'vue'
import Draggable from 'vuedraggable'
import { generateInclusiveCombination, createCharacterRange } from '@/combinatorics'
import { Color, Score, getStrengthTestService } from '@/cryptography/strength_test_service'
import { Password } from '@/redux/domain'
import { isSignalFinale, isSignalSuccess } from '@/redux/flow_signal'
import { showToast } from '@/redux/modules/ui/toast/actions'
import { canAccessApi } from '@/redux/modules/user/account/selectors'
import {
  commitShadow,
  integrateClique,
  cancelShadow,
  obliterateClique,
  cliqueIntegrationSignal,
  cliqueObliterationSignal,
  extractPassword,
  toggleCliquePin,
  keyPinTogglingSignal
} from '@/redux/modules/user/keys/actions'
import {
  Clique,
  getCliqueRepr,
  getFrontShadow,
  getCliqueRoot
} from '@/redux/modules/user/keys/selectors'
import StrengthScore from './StrengthScore.vue'

const EMPTY_PASSWORD: DeepReadonly<Password> = { value: '', tags: [''] }

const clonePassword = (password: DeepReadonly<Password>): Password => {
  return cloneDeep(password) as Password
}

enum AutosaveEventType {
  DELAYED_UPDATE = 'DELAYED_UPDATE',
  IMMEDIATE_UPDATE = 'IMMEDIATE_UPDATE',
  INTERRUPT = 'INTERRUPT'
}
interface DelayedUpdate {
  type: AutosaveEventType.DELAYED_UPDATE;
  password: Password;
}
interface ImmediateUpdate {
  type: AutosaveEventType.IMMEDIATE_UPDATE;
  password: Password;
}
interface Interrupt {
  type: AutosaveEventType.INTERRUPT;
}
type AutosaveEvent = DelayedUpdate | ImmediateUpdate | Interrupt

const PASSPHRASE_SUGGESTION_LENGTH = 12
const TYPEWRITER_DELAY_IN_MILLIS = 50

const makeSuggestion = (length: number): string => generateInclusiveCombination(
  [
    '@#$_&-+()/' + '*"\':;!?',
    createCharacterRange('0', '9'),
    createCharacterRange('A', 'Z'),
    createCharacterRange('a', 'z')
  ],
  length
)

export default Vue.extend({
  components: {
    draggable: Draggable,
    strengthScore: StrengthScore
  },
  props: {
    debounceMillis: {
      type: Number,
      default: null
    },
    clique: {
      type: Object as PropType<DeepReadonly<Clique>>,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    initEdit: {
      type: Boolean,
      default: false
    },
    scoreColor: {
      type: String as PropType<Color>,
      default: null
    }
  },
  data () {
    return {
      reveal: false,
      edited: this.initEdit,
      content: clonePassword(EMPTY_PASSWORD),
      togglingPin: false,
      autosaveQueue$: new Subject<AutosaveEvent>(),
      saving: false,
      deleting: false,
      delRequested: false,
      generator$: new Subject<void>()
    }
  },
  created () {
    this.$data.$actions.pipe(
      filter(isActionOf(keyPinTogglingSignal)),
      filter((action: ReturnType<typeof keyPinTogglingSignal>) => {
        return action.meta.clique === this.clique.name
      }),
      takeUntil(this.$data.$destruction)
    ).subscribe((action: ReturnType<typeof keyPinTogglingSignal>) => {
      if (isSignalFinale(action.payload)) {
        this.togglingPin = false
      }
    })
    this.autosaveQueue$.pipe(
      switchMap((event: AutosaveEvent) => {
        switch (event.type) {
          case AutosaveEventType.DELAYED_UPDATE:
            return iif(
              () => this.debounceMillis === null,
              of(event.password),
              timer(this.debounceMillis).pipe(mapTo(event.password))
            )
          case AutosaveEventType.IMMEDIATE_UPDATE:
            return of(event.password)
          case AutosaveEventType.INTERRUPT:
            return EMPTY
        }
      }),
      takeUntil(this.$data.$destruction)
    ).subscribe((password) => {
      this.dispatch(commitShadow({
        clique: this.clique.name,
        ...password
      }))
    })
    this.$data.$actions.pipe(
      filter(isActionOf(cliqueIntegrationSignal)),
      filter((action: ReturnType<typeof cliqueIntegrationSignal>) => {
        return action.meta.clique === this.clique.name
      }),
      takeUntil(this.$data.$destruction)
    ).subscribe((action: ReturnType<typeof cliqueIntegrationSignal>) => {
      if (isSignalFinale(action.payload)) {
        this.saving = false
      }
      if (isSignalSuccess(action.payload)) {
        this.edited = false
        this.$emit('save')
      }
    })
    this.$data.$actions.pipe(
      filter(isActionOf(cliqueObliterationSignal)),
      filter((action: ReturnType<typeof cliqueObliterationSignal>) => {
        return action.meta.clique === this.clique.name
      }),
      takeUntil(this.$data.$destruction)
    ).subscribe((action: ReturnType<typeof cliqueObliterationSignal>) => {
      if (isSignalFinale(action.payload)) {
        this.deleting = false
      }
      if (isSignalSuccess(action.payload)) {
        this.edited = false
        this.$emit('delete')
      }
    })
    this.generator$.pipe(
      switchMap(() => {
        this.content.value = ''
        const suggestion = makeSuggestion(PASSPHRASE_SUGGESTION_LENGTH)
        return interval(TYPEWRITER_DELAY_IN_MILLIS).pipe(
          take(suggestion.length),
          tap((index) => {
            this.setValue(suggestion.slice(0, index + 1))
          })
        )
      }),
      takeUntil(this.$data.$destruction)
    ).subscribe()
  },
  mounted () {
    if (this.initEdit) {
      this.focusLabel(0)
    }
  },
  computed: {
    passwordStrength (): Score {
      if (!this.edited) {
        return { value: 1, color: Color.GREEN }
      }
      return getStrengthTestService().score(this.content.value, this.content.tags)
    },
    canAccessApi (): boolean {
      return canAccessApi(this.$data.$state)
    },
    reprPassword (): DeepReadonly<Password> {
      return fn.pipe(
        getCliqueRepr(this.clique),
        option.fold(() => EMPTY_PASSWORD, extractPassword)
      )
    },
    rootPassword (): DeepReadonly<Password> {
      return fn.pipe(
        getCliqueRoot(this.clique),
        option.fold(() => EMPTY_PASSWORD, extractPassword)
      )
    },
    isValueEmpty (): boolean {
      return this.reprPassword.value.trim().length === 0
    },
    autosavePrompt (): boolean {
      return this.clique.shadows.length > 0
    },
    hasNoParent (): boolean {
      return this.clique.parent === null
    },
    isPinned (): boolean {
      return fn.pipe(
        getCliqueRoot(this.clique),
        option.fold(
          () => false,
          (root) => root.attrs.isPinned
        )
      )
    }
  },
  methods: {
    focusLabel (pointer: number) {
      const index = (pointer + this.content.tags.length) % this.content.tags.length
      ;(this.$refs.labels as HTMLInputElement[])[index].focus()
    },
    async copyText (string: string): Promise<void> {
      await navigator.clipboard.writeText(string)
      this.dispatch(showToast({ message: 'Done. Some clipboards retain history — be careful! 😱' }))
    },
    toggleReveal () {
      this.reveal = !this.reveal
    },
    togglePin () {
      this.dispatch(toggleCliquePin({
        clique: this.clique.name,
        isPinned: !this.isPinned
      }))
      this.togglingPin = true
    },
    edit (source: DeepReadonly<Password>) {
      this.reveal = false
      this.content = clonePassword(source)
      this.edited = true
    },
    autosave (autosaveEventType: AutosaveEventType.DELAYED_UPDATE | AutosaveEventType.IMMEDIATE_UPDATE) {
      this.autosaveQueue$.next({
        type: autosaveEventType,
        password: clonePassword(this.content)
      })
    },
    interruptAutosave () {
      this.autosaveQueue$.next({
        type: AutosaveEventType.INTERRUPT
      })
    },
    async addLabel () {
      this.content.tags.push('')
      await this.$nextTick()
      this.focusLabel(-1)
    },
    delLabel (index: number) {
      this.content.tags.splice(index, 1)
    },
    setLabel (index: number, value: string) {
      this.$set(this.content.tags, index, value)
      this.autosave(AutosaveEventType.DELAYED_UPDATE)
    },
    dndLabel () {
      // NOP.
    },
    reorder (result: string[]) {
      this.content.tags = result
      this.autosave(AutosaveEventType.DELAYED_UPDATE)
    },
    setValue (value: string) {
      this.content.value = value
      this.autosave(AutosaveEventType.DELAYED_UPDATE)
    },
    suggest () {
      this.generator$.next()
    },
    save () {
      // `extractPassword` to omit Vue properties.
      if (isEqual(extractPassword(this.content), this.rootPassword)) {
        this.cancel()
        return
      }
      this.saving = true
      this.autosave(AutosaveEventType.IMMEDIATE_UPDATE)
      this.dispatch(integrateClique({
        clique: this.clique.name
      }))
    },
    requestDel () {
      this.delRequested = true
    },
    abandonDel () {
      this.delRequested = false
    },
    remove () {
      this.delRequested = false
      this.deleting = true
      this.interruptAutosave()
      this.dispatch(obliterateClique({
        clique: this.clique.name
      }))
    },
    cancel () {
      this.interruptAutosave()
      this.edited = false
      this.dispatch(cancelShadow({
        clique: this.clique.name
      }))
      this.$emit('cancel')
    },
    discardDraft () {
      this.cancel()
    },
    restoreDraft () {
      this.edit(fn.pipe(
        getFrontShadow(this.clique),
        option.fold(() => EMPTY_PASSWORD, extractPassword)
      ))
    },
    editBaseline () {
      this.edit(this.reprPassword)
    }
  }
})
