



















































import { function as fn, option, map, eq } from 'fp-ts'
import { filter, takeUntil } from 'rxjs/operators'
import { DeepReadonly } from 'ts-essentials'
import Vue, { VueConstructor } from 'vue'
import { required, sameAs } from 'vuelidate/lib/validators'
import { ServiceChangeMasterKeyResponseError } from '@/api/definitions'
import StrengthScore from '@/components/StrengthScore.vue'
import { remoteDataErrorIndicator } from '@/components/form_validators'
import { Score, getStrengthTestService } from '@/cryptography/strength_test_service'
import { isActionSuccess } from '@/redux/flow_signal'
import { sessionUsername } from '@/redux/modules/session/selectors'
import { showToast } from '@/redux/modules/ui/toast/actions'
import { MasterKeyChangeFlowIndicator, changeMasterKey, masterKeyChangeReset, masterKeyChangeSignal } from '@/redux/modules/user/account/actions'
import { canAccessApi, masterKeyChange, MasterKeyChange, accountMail } from '@/redux/modules/user/account/selectors'

const currentIncorrectIndicator = remoteDataErrorIndicator(ServiceChangeMasterKeyResponseError.INVALIDCURRENTDIGEST)

const INDICATOR_TO_MESSAGE = new Map<MasterKeyChangeFlowIndicator, string>([
  [MasterKeyChangeFlowIndicator.REENCRYPTING, 'Re-encrypting'],
  [MasterKeyChangeFlowIndicator.MAKING_REQUEST, 'Making request']
])

interface Mixins {
  untouchedSinceDispatch: boolean;
  masterKeyChange: DeepReadonly<MasterKeyChange>;
}

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  components: {
    strengthScore: StrengthScore
  },
  props: { eagerPanel: { type: Boolean, default: false } },
  data () {
    return {
      current: '',
      renewal: '',
      repeat: '',
      untouchedSinceDispatch: false
    }
  },
  validations: {
    current: {
      correct () {
        return !currentIncorrectIndicator(this.masterKeyChange, this.untouchedSinceDispatch)
      }
    },
    renewal: { required },
    repeat: { sameAs: sameAs('renewal') }
  },
  created () {
    this.$data.$actions.pipe(
      filter(isActionSuccess(masterKeyChangeSignal)),
      takeUntil(this.$data.$destruction)
    ).subscribe(() => {
      this.current = ''
      this.renewal = ''
      this.repeat = ''
      this.untouchedSinceDispatch = false
      this.$v.$reset()
      this.dispatch(masterKeyChangeReset())
      this.dispatch(showToast({ message: this.$t('DONE') as string }))
      if (document.activeElement !== null) {
        ;(document.activeElement as HTMLInputElement).blur()
      }
    })
  },
  computed: {
    canAccessApi (): boolean {
      return canAccessApi(this.$data.$state)
    },
    username (): string | null {
      return sessionUsername(this.$data.$state)
    },
    accountMail (): string | null {
      return accountMail(this.$data.$state)
    },
    masterKeyChange (): DeepReadonly<MasterKeyChange> {
      return masterKeyChange(this.$data.$state)
    },
    currentErrors (): { [key: string]: boolean } {
      return {
        [this.$t('INVALID_CURRENT_PASSWORD') as string]: !this.$v.current.correct
      }
    },
    renewalErrors (): { [key: string]: boolean } {
      return {
        [this.$t('PASSWORD_CANNOT_BE_EMPTY') as string]: !this.$v.renewal.required
      }
    },
    repeatErrors (): { [key: string]: boolean } {
      return {
        [this.$t('PASSWORDS_DO_NOT_MATCH') as string]: !this.$v.repeat.sameAs
      }
    },
    passwordStrength (): Score {
      const inputs: string[] = []
      if (this.username !== null) {
        inputs.push(this.username)
      }
      if (this.accountMail !== null) {
        inputs.push(this.accountMail)
      }
      return getStrengthTestService().score(this.renewal, inputs)
    },
    indicatorMessage (): string | null {
      return fn.pipe(
        this.masterKeyChange.indicator,
        option.chain((indicator) => map.lookup(eq.eqStrict)(indicator, INDICATOR_TO_MESSAGE)),
        option.getOrElse<string | null>(() => null)
      )
    },
    hasIndicatorMessage (): boolean {
      return this.indicatorMessage !== null
    }
  },
  methods: {
    setCurrent (value: string) {
      this.current = value
      this.untouchedSinceDispatch = false
    },
    setRenewal (value: string) {
      this.renewal = value
      this.untouchedSinceDispatch = false
    },
    setRepeat (value: string) {
      this.repeat = value
      this.untouchedSinceDispatch = false
    },
    submit () {
      if (!this.hasIndicatorMessage) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.untouchedSinceDispatch = true
          this.dispatch(changeMasterKey({
            current: this.current,
            renewal: this.renewal
          }))
        }
      }
    }
  },
  beforeDestroy () {
    this.dispatch(masterKeyChangeReset())
  }
})
