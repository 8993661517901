





























import { function as fn, array, option } from 'fp-ts'
import { DeepReadonly } from 'ts-essentials'
import Vue, { PropType } from 'vue'
import { Color } from '@/cryptography/strength_test_service'
import { Clique, createEmptyClique } from '@/redux/modules/user/keys/selectors'
import PasswordComponent from './Password.vue'
import { PASSWORD_MIN_HEIGHT } from './dimensions'

export default Vue.extend({
  components: {
    password: PasswordComponent
  },
  props: {
    additions: {
      type: Array as PropType<string[]>,
      default: () => [] as string[]
    },
    cliques: {
      type: Array as PropType<DeepReadonly<Clique>[]>,
      default: () => [] as DeepReadonly<Clique>[]
    },
    idToScore: {
      type: Object as PropType<{ [key: string]: Color }>,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: true
    },
    colCountLg: {
      type: Number,
      default: 3
    },
    colCountMd: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      passwordMinHeight: PASSWORD_MIN_HEIGHT
    }
  },
  computed: {
    items (): DeepReadonly<Clique[]> {
      const source = [
        ...this.additions.map((addition) => fn.pipe(
          this.cliques,
          array.findFirst((clique: DeepReadonly<Clique>) => clique.name === addition),
          option.getOrElse(() => createEmptyClique(addition))
        )),
        ...this.cliques.filter((clique) => !this.additions.includes(clique.name))
      ]
      const result: DeepReadonly<Clique>[] = []
      for (let bucket = 0; bucket < this.columnCount; ++bucket) {
        for (let index = bucket; index < source.length; index += this.columnCount) {
          result.push(source[index])
        }
      }
      return result
    },
    columnCount (): number {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return this.colCountLg
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.colCountMd
      }
      return 1
    },
    height (): number {
      return Math.ceil(this.items.length / this.columnCount)
    },
    fullColumns (): number {
      const mod = this.items.length % this.columnCount
      return mod === 0 ? this.columnCount : mod
    },
    cellsInFull (): number {
      return this.fullColumns * this.height
    },
    listStyles (): { [key: string]: string } {
      return {
        // Prevents width expansion on scrolling.
        width: '100%',
        columns: String(this.columnCount),
        columnGap: '0'
      }
    }
  },
  methods: {
    itemStyles (index: number): { [key: string]: string } {
      return {
        'break-after': (index >= this.cellsInFull
          ? (index - this.cellsInFull + 1) % (this.height - 1) === 0
          : (index + 1) % this.height === 0) ? 'column' : 'auto',
        'break-inside': 'avoid',
        // Firefox still breaks single item by default.
        overflow: 'hidden'
      }
    },
    finalize (cliqueName: string, attach: boolean) {
      if (this.additions.includes(cliqueName)) {
        this.$emit('addition', cliqueName, attach)
      }
    }
  }
})
