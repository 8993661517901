




























































import Vue, { VueConstructor } from 'vue'
import trianglify from 'trianglify'
import Page from '@/components/Page.vue'
import { getFlags } from '@/flags'
import { isAuthenticated } from '@/redux/modules/user/account/selectors'

interface Mixins {
  resizeObserver: any;
}

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  components: {
    page: Page
  },
  data () {
    return {
      version: getFlags().version
    }
  },
  computed: {
    isAuthenticated () {
      return isAuthenticated(this.$data.$state)
    },
    nameDynamicClasses (): { [key: string]: boolean } {
      return {
        'text-h1': this.$vuetify.breakpoint.lgAndUp,
        'text-h2': this.$vuetify.breakpoint.mdOnly,
        'text-h3': this.$vuetify.breakpoint.smAndDown
      }
    },
    ipaDynamicClasses (): { [key: string]: boolean } {
      return {
        'mt-n3': this.$vuetify.breakpoint.lgAndUp,
        'mt-n2': this.$vuetify.breakpoint.mdOnly,
        'mt-n1': this.$vuetify.breakpoint.smAndDown
      }
    },
    descriptionDynamicClasses (): { [key: string]: boolean } {
      return {
        'text-h3': this.$vuetify.breakpoint.lgAndUp,
        'text-h4': this.$vuetify.breakpoint.mdOnly,
        'text-h5': this.$vuetify.breakpoint.smAndDown
      }
    }
  },
  methods: {
    renderBackground () {
      const background = this.$refs.background as HTMLElement
      const pattern = trianglify({
        width: background.clientWidth,
        height: background.clientHeight,
        xColors: ['#052842', '#54b5f9', '#db4367', '#b31d1f'],
        seed: 'keyring'
      })
      pattern.toCanvas(this.$refs.backgroundCanvas as HTMLElement)
    }
  },
  created () {
    // https://github.com/microsoft/TypeScript/issues/37861
    this.resizeObserver = new (window as any).ResizeObserver(() => {
      this.renderBackground()
    })
  },
  mounted () {
    this.resizeObserver.observe(this.$refs.background)
  },
  beforeDestroy () {
    this.resizeObserver.unobserve(this.$refs.background)
  }
})
